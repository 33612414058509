<template>
  <!-- we hide the whole app if we're in the iosGTM frame -->
  <!-- it still needs to load so we get page meta, but we don't need to render the dom. -->
  <NuxtLayout v-show="!isIosGtmFrame">
    <NuxtPage />

    <OfflineWarningModal />
  </NuxtLayout>

  <div v-if="isNative && isIos" class="fixed -bottom-10 w-0 h-0 bg-white p-8 z-[-100]">
    <div class="w-0 h-0">
      <iframe
        :id="iosGtmFrameFrameId"
        ref="frame"
        :src="`https://hubofhope.co.uk?${iosGtmFrameRouteParam}=true`"
        class="w-full h-full"
      ></iframe>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useIsIosGtmFrame, iosGtmFrameFrameId, iosGtmFrameRouteParam } from './composables/useIsIosGtmFrame'
import { useWindowPostMessage } from './composables/useWindowPostMessage'
const { isNative, isIos } = useRuntimeConfig().public

const { frame, isIosGtmFrame } = useIsIosGtmFrame()

// set up the post message listeners on the window object
// and handle the actions from post messages.
// this is used to communicate with the iframe from the native app for GTM
useWindowPostMessage()

onMounted(async () => {
  // init as soon as we can so that we have the data layer ready
  useGaEvent('init', null)

  if (isNative) {
    // hide the splash screen on mounted on mobile apps
    const { SplashScreen } = await import('@capacitor/splash-screen')
    SplashScreen.hide()

    // handle back button on mobile apps to go browser history back or exit the app
    const { App } = await import('@capacitor/app')
    App.addListener('backButton', ({ canGoBack }) => {
      if (!canGoBack) {
        App.exitApp()
      } else {
        useRouter().back()
      }
    })

    if (isIos) {
      // watch the route path and send it to the iframe
      // the iframe will route to the same path
      // this will fire gtm events in the iframe for the page view
      const computedRoutePath = computed(() => useRoute().path)
      watch(computedRoutePath, (value) => {
        if (frame.value) {
          frame.value?.contentWindow?.postMessage(
            { type: 'command', command: 'router.push', args: [value] },
            '*'
          )
        }
      })
    }
  }
})
</script>
