// Formats the military time to an ISO string so we can use it with dayjs
export const militaryTimeToISOString = (timeString: string) => {
  // Get the current date
  const now = new Date()

  // Extract hours and minutes from the time string (assuming format 'HH:MM')
  const hours = parseInt(timeString.substring(0, 2), 10)
  // mins will always be last 2 characters. We may or may not have a colon
  const minutes = parseInt(timeString.substring(timeString.length - 2), 10)

  // Set the time for the current date
  now.setHours(hours, minutes, 0, 0)

  return now.toISOString()
}
