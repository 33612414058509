import type { IFilterXSSOptions } from 'xss'

/**
 * This is the config for xss validator that is shared between the
 * server and the client. It is used to filter out any xss attacks.
 * The server will 400 before it hits the route handler if the request
 * is different from the validated request. So we need to filter
 * for xss all client data, which will then be filtered in the same way on
 * the server - then they should match and the request gets through to the route handler.
 */

export const xssConfig = (): IFilterXSSOptions => ({
  whiteList: {
    p: ['class'],
    br: ['class'],
    ul: ['class'],
    ol: ['class'],
    li: ['class'],
    strong: ['class'],
    em: ['class'],
    a: ['href', 'title', 'target', 'rel', 'aria-label', 'class']
  },
  singleQuotedAttributeValue: true
})
