<template>
  <NuxtLayout>
    <section>
      <div class="max-w-2xl mx-auto py-24 px-8">
        <div class="mb-6 text-center">
          <span class="block text-4xl font-bold text-red mb-4 md:text-6xl"> {{ error?.statusCode }}</span>
          <div>
            <h1 class="font-bold mb-4 text-2xl md:text-4xl">
              <span v-if="error?.statusCode === 404">{{ error.message }}</span>
              <span v-else-if="error?.statusCode === 500">Server error</span>
              <span v-else>An error occurred</span>
            </h1>
            <p class="text-gray-600 font-light">
              <span v-if="error?.statusCode === 404">
                Please check the URL in the address bar and try again.
              </span>
              <span v-else-if="error?.statusCode === 500">
                That means something isn't working on our end. <br />
                Here's what you can do:
                <ul class="list-disc list-inside text-left mt-4">
                  <li>
                    For urgent medical attention or when there is a risk of life, please call 999 or go to
                    your nearest Accident and Emergency (A&E) Department.
                  </li>
                  <li>
                    For crisis mental health support:
                    <ul class="list-disc list-inside ml-4">
                      <li>
                        Call NHS at
                        <a href="tel:111" class="hover:underline hover:cursor-pointer">111</a> and select
                        option 2 (service available across England, Wales, and Scotland, open 24 hours)
                      </li>
                      <li>
                        Call Lifeline at
                        <a href="tel:0808 808 8000" class="hover:underline hover:cursor-pointer"
                          >0808 808 8000</a
                        >
                        (service available across Northern Ireland, open 24 hours)
                      </li>
                      <li>
                        Call Samaritans at
                        <a href="tel:116 123">116 123</a>
                      </li>
                      <li>Access 24/7 crisis text support from SHOUT 85258</li>
                    </ul>
                  </li>
                </ul>
              </span>
              <span v-else>
                An error has occurred on the website. We are currently working to resolve this issue. Please
                try again later.
              </span>
            </p>
          </div>
        </div>
        <div v-if="error?.statusCode === 404" class="flex flex-wrap gap-2 justify-center">
          <UButton label="Get urgent help" color="pink" @click="handleError('/need-help-now')" />
          <UButton label="Get support" color="green" @click="handleError('/services')" />
          <UButton
            label="Go to home page"
            color="white"
            :ui="{ color: { white: { solid: 'md:ring-green' } } }"
            @click="handleError('/')"
          />
        </div>
        <div v-else>
          Please wait a moment before trying again, or return to the
          <NuxtLink href="/" class="underline">Hub of Hope homepage</NuxtLink>
        </div>
      </div>
    </section>
  </NuxtLayout>
</template>
<script setup lang="ts">
const error = useError()
const handleError = (relUrl: string) => {
  clearError({
    redirect: relUrl
  })
}
</script>
