import { default as about_45us4Sgh7ofdfSMeta } from "/home/runner/work/hub-of-hope/hub-of-hope/pages/about-us.vue?macro=true";
import { default as audit_45logNCJLeEZVG4Meta } from "/home/runner/work/hub-of-hope/hub-of-hope/pages/admin/audit-log.vue?macro=true";
import { default as indextF1jmYSDe7Meta } from "/home/runner/work/hub-of-hope/hub-of-hope/pages/admin/change-requests/[uid]/index.vue?macro=true";
import { default as indexq9sd66ltLWMeta } from "/home/runner/work/hub-of-hope/hub-of-hope/pages/admin/dashboard/index.vue?macro=true";
import { default as devopscxTibrTZseMeta } from "/home/runner/work/hub-of-hope/hub-of-hope/pages/admin/devops.vue?macro=true";
import { default as email_45templatesDxFyttxhhfMeta } from "/home/runner/work/hub-of-hope/hub-of-hope/pages/admin/email-templates.vue?macro=true";
import { default as faq_45managementHrVbZb4SKzMeta } from "/home/runner/work/hub-of-hope/hub-of-hope/pages/admin/faq-management.vue?macro=true";
import { default as indexdTqS5CpR15Meta } from "/home/runner/work/hub-of-hope/hub-of-hope/pages/admin/iframe-management/[uid]/index.vue?macro=true";
import { default as indexITHAl9FJvYMeta } from "/home/runner/work/hub-of-hope/hub-of-hope/pages/admin/iframe-management/index.vue?macro=true";
import { default as indexdhBRumT0DEMeta } from "/home/runner/work/hub-of-hope/hub-of-hope/pages/admin/index.vue?macro=true";
import { default as indexG8YmKDdjxQMeta } from "/home/runner/work/hub-of-hope/hub-of-hope/pages/admin/new-service-request/[uid]/index.vue?macro=true";
import { default as indexUJPiXGkB9LMeta } from "/home/runner/work/hub-of-hope/hub-of-hope/pages/admin/new-service/index.vue?macro=true";
import { default as _91expiry_93rTDZ6puZ97Meta } from "/home/runner/work/hub-of-hope/hub-of-hope/pages/admin/quick-actions/confirm-service-details/[uid]/expires/[expiry].vue?macro=true";
import { default as review_45reportsoeYU1X7IMuMeta } from "/home/runner/work/hub-of-hope/hub-of-hope/pages/admin/review-reports.vue?macro=true";
import { default as edito5jNqzDZrvMeta } from "/home/runner/work/hub-of-hope/hub-of-hope/pages/admin/service/[uid]/edit.vue?macro=true";
import { default as indexXZJRhPMNeQMeta } from "/home/runner/work/hub-of-hope/hub-of-hope/pages/admin/service/[uid]/index.vue?macro=true";
import { default as change_45requestsVzREajpoOmMeta } from "/home/runner/work/hub-of-hope/hub-of-hope/pages/admin/services/change-requests.vue?macro=true";
import { default as expired_45servicestRxxrZLbziMeta } from "/home/runner/work/hub-of-hope/hub-of-hope/pages/admin/services/expired-services.vue?macro=true";
import { default as new_45applications7ctAl1IVJMMeta } from "/home/runner/work/hub-of-hope/hub-of-hope/pages/admin/services/new-applications.vue?macro=true";
import { default as overviewlULCqb1EY2Meta } from "/home/runner/work/hub-of-hope/hub-of-hope/pages/admin/services/overview.vue?macro=true";
import { default as pending_45updates9Z4wncnQkzMeta } from "/home/runner/work/hub-of-hope/hub-of-hope/pages/admin/services/pending-updates.vue?macro=true";
import { default as indexUe0Qsb2WtwMeta } from "/home/runner/work/hub-of-hope/hub-of-hope/pages/admin/services/removed-listing/[uid]/index.vue?macro=true";
import { default as removed_45listingsWgTgTMUqZcMeta } from "/home/runner/work/hub-of-hope/hub-of-hope/pages/admin/services/removed-listings.vue?macro=true";
import { default as support_45tagseLXzUQn8cTMeta } from "/home/runner/work/hub-of-hope/hub-of-hope/pages/admin/support-tags.vue?macro=true";
import { default as user_45managementT1LmY0vgDxMeta } from "/home/runner/work/hub-of-hope/hub-of-hope/pages/admin/user-management.vue?macro=true";
import { default as actionlC5xL7yAfLMeta } from "/home/runner/work/hub-of-hope/hub-of-hope/pages/auth/action.vue?macro=true";
import { default as cookie_45policyRis71kfuN1Meta } from "/home/runner/work/hub-of-hope/hub-of-hope/pages/cookie-policy.vue?macro=true";
import { default as disclaimerb0aVytVeFzMeta } from "/home/runner/work/hub-of-hope/hub-of-hope/pages/disclaimer.vue?macro=true";
import { default as faqsoyXhLX1E5DMeta } from "/home/runner/work/hub-of-hope/hub-of-hope/pages/faqs.vue?macro=true";
import { default as forgot_45passwordxnHGT6Z7BMMeta } from "/home/runner/work/hub-of-hope/hub-of-hope/pages/forgot-password.vue?macro=true";
import { default as how_45it_45workssvbX4cOZFhMeta } from "/home/runner/work/hub-of-hope/hub-of-hope/pages/how-it-works.vue?macro=true";
import { default as indexxn5kpyAPl9Meta } from "/home/runner/work/hub-of-hope/hub-of-hope/pages/iframe/services/index.vue?macro=true";
import { default as test_45embedH3efwssUNwMeta } from "/home/runner/work/hub-of-hope/hub-of-hope/pages/iframe/services/test-embed.vue?macro=true";
import { default as testvrXbNdR45uMeta } from "/home/runner/work/hub-of-hope/hub-of-hope/pages/iframe/services/test.vue?macro=true";
import { default as indexTyh5N5yCC8Meta } from "/home/runner/work/hub-of-hope/hub-of-hope/pages/index.vue?macro=true";
import { default as need_45help_45nowW6yJAYTRtSMeta } from "/home/runner/work/hub-of-hope/hub-of-hope/pages/need-help-now.vue?macro=true";
import { default as partnershipsOV5ere1jYqMeta } from "/home/runner/work/hub-of-hope/hub-of-hope/pages/partnerships.vue?macro=true";
import { default as privacy_45policyffkpOBZlOHMeta } from "/home/runner/work/hub-of-hope/hub-of-hope/pages/privacy-policy.vue?macro=true";
import { default as _91expiry_939P5PJhLqdHMeta } from "/home/runner/work/hub-of-hope/hub-of-hope/pages/quick-actions/confirm-service-details/[uid]/expires/[expiry].vue?macro=true";
import { default as register_45your_45serviceYVPGSyJcXwMeta } from "/home/runner/work/hub-of-hope/hub-of-hope/pages/register-your-service.vue?macro=true";
import { default as _91uid_93BVVOcsDlYWMeta } from "/home/runner/work/hub-of-hope/hub-of-hope/pages/resource/[uid].vue?macro=true";
import { default as resourcesOx7BA8nQl4Meta } from "/home/runner/work/hub-of-hope/hub-of-hope/pages/resources.vue?macro=true";
import { default as approve9MBrIrVFy4Meta } from "/home/runner/work/hub-of-hope/hub-of-hope/pages/service-review-cycle/[uid]/approve.vue?macro=true";
import { default as indexh3x8fQ9YWsMeta } from "/home/runner/work/hub-of-hope/hub-of-hope/pages/service-review-cycle/[uid]/index.vue?macro=true";
import { default as indexrNImIG8RtNMeta } from "/home/runner/work/hub-of-hope/hub-of-hope/pages/service/[uid]/index.vue?macro=true";
import { default as indexQXsptByNVXMeta } from "/home/runner/work/hub-of-hope/hub-of-hope/pages/services/index.vue?macro=true";
import { default as terms_45conditionsr2ZjOjGNzlMeta } from "/home/runner/work/hub-of-hope/hub-of-hope/pages/terms-conditions.vue?macro=true";
import { default as component_45stubmagEUAV9PjMeta } from "/home/runner/work/hub-of-hope/hub-of-hope/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubmagEUAV9Pj } from "/home/runner/work/hub-of-hope/hub-of-hope/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "about-us",
    path: "/about-us",
    component: () => import("/home/runner/work/hub-of-hope/hub-of-hope/pages/about-us.vue")
  },
  {
    name: "admin-audit-log",
    path: "/admin/audit-log",
    meta: audit_45logNCJLeEZVG4Meta || {},
    component: () => import("/home/runner/work/hub-of-hope/hub-of-hope/pages/admin/audit-log.vue")
  },
  {
    name: "admin-change-requests-uid",
    path: "/admin/change-requests/:uid()",
    meta: indextF1jmYSDe7Meta || {},
    component: () => import("/home/runner/work/hub-of-hope/hub-of-hope/pages/admin/change-requests/[uid]/index.vue")
  },
  {
    name: "admin-dashboard",
    path: "/admin/dashboard",
    meta: indexq9sd66ltLWMeta || {},
    component: () => import("/home/runner/work/hub-of-hope/hub-of-hope/pages/admin/dashboard/index.vue")
  },
  {
    name: "admin-devops",
    path: "/admin/devops",
    meta: devopscxTibrTZseMeta || {},
    component: () => import("/home/runner/work/hub-of-hope/hub-of-hope/pages/admin/devops.vue")
  },
  {
    name: "admin-email-templates",
    path: "/admin/email-templates",
    meta: email_45templatesDxFyttxhhfMeta || {},
    component: () => import("/home/runner/work/hub-of-hope/hub-of-hope/pages/admin/email-templates.vue")
  },
  {
    name: "admin-faq-management",
    path: "/admin/faq-management",
    meta: faq_45managementHrVbZb4SKzMeta || {},
    component: () => import("/home/runner/work/hub-of-hope/hub-of-hope/pages/admin/faq-management.vue")
  },
  {
    name: "admin-iframe-management-uid",
    path: "/admin/iframe-management/:uid()",
    meta: indexdTqS5CpR15Meta || {},
    component: () => import("/home/runner/work/hub-of-hope/hub-of-hope/pages/admin/iframe-management/[uid]/index.vue")
  },
  {
    name: "admin-iframe-management",
    path: "/admin/iframe-management",
    meta: indexITHAl9FJvYMeta || {},
    component: () => import("/home/runner/work/hub-of-hope/hub-of-hope/pages/admin/iframe-management/index.vue")
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/home/runner/work/hub-of-hope/hub-of-hope/pages/admin/index.vue")
  },
  {
    name: "admin-new-service-request-uid",
    path: "/admin/new-service-request/:uid()",
    meta: indexG8YmKDdjxQMeta || {},
    component: () => import("/home/runner/work/hub-of-hope/hub-of-hope/pages/admin/new-service-request/[uid]/index.vue")
  },
  {
    name: "admin-new-service",
    path: "/admin/new-service",
    meta: indexUJPiXGkB9LMeta || {},
    component: () => import("/home/runner/work/hub-of-hope/hub-of-hope/pages/admin/new-service/index.vue")
  },
  {
    name: "admin-quick-actions-confirm-service-details-uid-expires-expiry",
    path: "/admin/quick-actions/confirm-service-details/:uid()/expires/:expiry()",
    component: () => import("/home/runner/work/hub-of-hope/hub-of-hope/pages/admin/quick-actions/confirm-service-details/[uid]/expires/[expiry].vue")
  },
  {
    name: "admin-review-reports",
    path: "/admin/review-reports",
    meta: review_45reportsoeYU1X7IMuMeta || {},
    component: () => import("/home/runner/work/hub-of-hope/hub-of-hope/pages/admin/review-reports.vue")
  },
  {
    name: "admin-service-uid-edit",
    path: "/admin/service/:uid()/edit",
    meta: edito5jNqzDZrvMeta || {},
    component: () => import("/home/runner/work/hub-of-hope/hub-of-hope/pages/admin/service/[uid]/edit.vue")
  },
  {
    name: "admin-service-uid",
    path: "/admin/service/:uid()",
    meta: indexXZJRhPMNeQMeta || {},
    component: () => import("/home/runner/work/hub-of-hope/hub-of-hope/pages/admin/service/[uid]/index.vue")
  },
  {
    name: "admin-services-change-requests",
    path: "/admin/services/change-requests",
    meta: change_45requestsVzREajpoOmMeta || {},
    component: () => import("/home/runner/work/hub-of-hope/hub-of-hope/pages/admin/services/change-requests.vue")
  },
  {
    name: "admin-services-expired-services",
    path: "/admin/services/expired-services",
    meta: expired_45servicestRxxrZLbziMeta || {},
    component: () => import("/home/runner/work/hub-of-hope/hub-of-hope/pages/admin/services/expired-services.vue")
  },
  {
    name: "admin-services-new-applications",
    path: "/admin/services/new-applications",
    meta: new_45applications7ctAl1IVJMMeta || {},
    component: () => import("/home/runner/work/hub-of-hope/hub-of-hope/pages/admin/services/new-applications.vue")
  },
  {
    name: "admin-services-overview",
    path: "/admin/services/overview",
    meta: overviewlULCqb1EY2Meta || {},
    component: () => import("/home/runner/work/hub-of-hope/hub-of-hope/pages/admin/services/overview.vue")
  },
  {
    name: "admin-services-pending-updates",
    path: "/admin/services/pending-updates",
    meta: pending_45updates9Z4wncnQkzMeta || {},
    component: () => import("/home/runner/work/hub-of-hope/hub-of-hope/pages/admin/services/pending-updates.vue")
  },
  {
    name: "admin-services-removed-listing-uid",
    path: "/admin/services/removed-listing/:uid()",
    meta: indexUe0Qsb2WtwMeta || {},
    component: () => import("/home/runner/work/hub-of-hope/hub-of-hope/pages/admin/services/removed-listing/[uid]/index.vue")
  },
  {
    name: "admin-services-removed-listings",
    path: "/admin/services/removed-listings",
    meta: removed_45listingsWgTgTMUqZcMeta || {},
    component: () => import("/home/runner/work/hub-of-hope/hub-of-hope/pages/admin/services/removed-listings.vue")
  },
  {
    name: "admin-support-tags",
    path: "/admin/support-tags",
    meta: support_45tagseLXzUQn8cTMeta || {},
    component: () => import("/home/runner/work/hub-of-hope/hub-of-hope/pages/admin/support-tags.vue")
  },
  {
    name: "admin-user-management",
    path: "/admin/user-management",
    meta: user_45managementT1LmY0vgDxMeta || {},
    component: () => import("/home/runner/work/hub-of-hope/hub-of-hope/pages/admin/user-management.vue")
  },
  {
    name: "auth-action",
    path: "/auth/action",
    component: () => import("/home/runner/work/hub-of-hope/hub-of-hope/pages/auth/action.vue")
  },
  {
    name: "cookie-policy",
    path: "/cookie-policy",
    component: () => import("/home/runner/work/hub-of-hope/hub-of-hope/pages/cookie-policy.vue")
  },
  {
    name: "disclaimer",
    path: "/disclaimer",
    component: () => import("/home/runner/work/hub-of-hope/hub-of-hope/pages/disclaimer.vue")
  },
  {
    name: "faqs",
    path: "/faqs",
    component: () => import("/home/runner/work/hub-of-hope/hub-of-hope/pages/faqs.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    component: () => import("/home/runner/work/hub-of-hope/hub-of-hope/pages/forgot-password.vue")
  },
  {
    name: "how-it-works",
    path: "/how-it-works",
    component: () => import("/home/runner/work/hub-of-hope/hub-of-hope/pages/how-it-works.vue")
  },
  {
    name: "iframe-services",
    path: "/iframe/services",
    meta: indexxn5kpyAPl9Meta || {},
    component: () => import("/home/runner/work/hub-of-hope/hub-of-hope/pages/iframe/services/index.vue")
  },
  {
    name: "iframe-services-test-embed",
    path: "/iframe/services/test-embed",
    component: () => import("/home/runner/work/hub-of-hope/hub-of-hope/pages/iframe/services/test-embed.vue")
  },
  {
    name: "iframe-services-test",
    path: "/iframe/services/test",
    component: () => import("/home/runner/work/hub-of-hope/hub-of-hope/pages/iframe/services/test.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/hub-of-hope/hub-of-hope/pages/index.vue")
  },
  {
    name: "need-help-now",
    path: "/need-help-now",
    component: () => import("/home/runner/work/hub-of-hope/hub-of-hope/pages/need-help-now.vue")
  },
  {
    name: "partnerships",
    path: "/partnerships",
    component: () => import("/home/runner/work/hub-of-hope/hub-of-hope/pages/partnerships.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/home/runner/work/hub-of-hope/hub-of-hope/pages/privacy-policy.vue")
  },
  {
    name: "quick-actions-confirm-service-details-uid-expires-expiry",
    path: "/quick-actions/confirm-service-details/:uid()/expires/:expiry()",
    component: () => import("/home/runner/work/hub-of-hope/hub-of-hope/pages/quick-actions/confirm-service-details/[uid]/expires/[expiry].vue")
  },
  {
    name: "register-your-service",
    path: "/register-your-service",
    component: () => import("/home/runner/work/hub-of-hope/hub-of-hope/pages/register-your-service.vue")
  },
  {
    name: "resource-uid",
    path: "/resource/:uid()",
    component: () => import("/home/runner/work/hub-of-hope/hub-of-hope/pages/resource/[uid].vue")
  },
  {
    name: "resources",
    path: "/resources",
    component: () => import("/home/runner/work/hub-of-hope/hub-of-hope/pages/resources.vue")
  },
  {
    name: "service-review-cycle-uid-approve",
    path: "/service-review-cycle/:uid()/approve",
    component: () => import("/home/runner/work/hub-of-hope/hub-of-hope/pages/service-review-cycle/[uid]/approve.vue")
  },
  {
    name: "service-review-cycle-uid",
    path: "/service-review-cycle/:uid()",
    meta: indexh3x8fQ9YWsMeta || {},
    component: () => import("/home/runner/work/hub-of-hope/hub-of-hope/pages/service-review-cycle/[uid]/index.vue")
  },
  {
    name: "service-uid",
    path: "/service/:uid()",
    component: () => import("/home/runner/work/hub-of-hope/hub-of-hope/pages/service/[uid]/index.vue")
  },
  {
    name: "services",
    path: "/services",
    meta: indexQXsptByNVXMeta || {},
    component: () => import("/home/runner/work/hub-of-hope/hub-of-hope/pages/services/index.vue")
  },
  {
    name: "terms-conditions",
    path: "/terms-conditions",
    component: () => import("/home/runner/work/hub-of-hope/hub-of-hope/pages/terms-conditions.vue")
  },
  {
    name: component_45stubmagEUAV9PjMeta?.name,
    path: "/page/:pathMatch(.*)",
    component: component_45stubmagEUAV9Pj
  }
]