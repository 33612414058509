import type { GoogleAutocompleteSuggestion } from '~/types/GoogleAutocompleteSuggestion'
export const useServicesLocationStore = defineStore('services-location', () => {
  const { filters } = useServiceSearchStore()

  const selectedLocationSuggestion = ref<GoogleAutocompleteSuggestion | undefined>()

  const location = computed({
    get: () => {
      return { latitude: filters.coordinatesLat, longitude: filters.coordinatesLon }
    },
    set: (value: { latitude?: number; longitude?: number }) => {
      filters.coordinatesLat = value.latitude
      filters.coordinatesLon = value.longitude
    }
  })

  return { location, selectedLocationSuggestion }
})
