import type { OpeningTimes } from '~/types/OpeningTimes'
import { getOpeningTimesForCurrentDay } from './getOpeningTimesForCurrentDay'
import { nowIsWithinOpeningTimes } from './nowIsWithinOpeningTimes'

export const serviceIsOpenNow = (openingTimes: OpeningTimes) => {
  const openingTimesForCurrentDay = getOpeningTimesForCurrentDay(openingTimes)
  const openingHoursForCurrentDay = openingTimesForCurrentDay.openingHours

  // Before we check todays opening times let's just check the isClosed isn't ticked for today
  if (openingTimesForCurrentDay.isClosed === true) {
    return false
  } else if (openingTimesForCurrentDay.open24Hours === true) {
    // If the service is open 24hrs for today then it's obviously open now
    return true
  } else if (openingTimesForCurrentDay.hasIrregularHours === true) {
    // If there are irregular hours for today then service can't be considered open or closed so we show irregular hours text instead
    return null
  } else {
    // Otherwise loop through opening hours for today and check if current time is between any of them
    return openingHoursForCurrentDay.some((openingHour) => nowIsWithinOpeningTimes(openingHour))
  }
}
