/**
 * These are the functions that can be called from the parent window from the iframe.
 * Used for GTM.
 */
export const useWindowPostMessage = () => {
  const actions = {
    'router.push': (to: string) => {
      useRouter().push(to)
    },
    alert: (message: string) => {
      alert(message)
    },
    'dataLayer.push': (data: string) => {
      if (window.dataLayer) {
        window.dataLayer.push(JSON.parse(data))
      }
    },
    datalayerLog: () => {
      window.parent.postMessage(
        { type: 'command', command: 'parentLog', args: [JSON.stringify(window.dataLayer)] },
        '*'
      )
    },
    parentLog: (data: string) => {
      try {
        const json = JSON.parse(data)
        console.log('parentLog', json)
      } catch {
        console.log('parentLog', data)
      }
    },
    acceptAnalyticsCookies: () => {
      try {
        window.parent.postMessage(
          { type: 'command', command: 'parentLog', args: ['accepting analytics'] },
          '*'
        )
        useNuxtApp().$cookieConsent.acceptCategory('analytics')
        useNuxtApp().$cookieConsent.hide()
        window.parent.postMessage(
          { type: 'command', command: 'parentLog', args: ['analytics accepted'] },
          '*'
        )
      } catch (e) {
        console.error(e)
        if (e instanceof Error) {
          console.error(e.message)
          window.parent.postMessage(
            { type: 'command', command: 'parentLog', args: ['error: acceptAnalyticsCookies; ' + e.message] },
            '*'
          )
        }
      }
    }
  }

  const handlePostMessage = (event: MessageEvent) => {
    // if (event.origin !== 'capacitor://localhost') return

    const { type, args } = event.data

    const command = event.data.command as keyof typeof actions

    if (type === 'command' && actions[command]) {
      try {
        /* @ts-expect-error - we know this is a valid command - args are arbitrary */
        actions[command](...args)
      } catch (e) {
        console.error(e)
      }
    }
  }

  onMounted(() => {
    window.addEventListener('message', handlePostMessage)
  })

  onUnmounted(() => {
    window.removeEventListener('message', handlePostMessage)
  })
}
