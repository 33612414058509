import payload_plugin_qLmFnukI99 from "/home/runner/work/hub-of-hope/hub-of-hope/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_4sVQNw7RlN from "/home/runner/work/hub-of-hope/hub-of-hope/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/runner/work/hub-of-hope/hub-of-hope/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/hub-of-hope/hub-of-hope/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/runner/work/hub-of-hope/hub-of-hope/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/runner/work/hub-of-hope/hub-of-hope/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/runner/work/hub-of-hope/hub-of-hope/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/hub-of-hope/hub-of-hope/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_zbacBAXhj0 from "/home/runner/work/hub-of-hope/hub-of-hope/.nuxt/modules/@nuxt-scripts/plugin.mjs";
import plugin_vue3_YdLad5Mpq3 from "/home/runner/work/hub-of-hope/hub-of-hope/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/hub-of-hope/hub-of-hope/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/hub-of-hope/hub-of-hope/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import primevue_plugin_egKpok8Auk from "/home/runner/work/hub-of-hope/hub-of-hope/.nuxt/primevue-plugin.mjs";
import plugin_client_XxbS7tGyhp from "/home/runner/work/hub-of-hope/hub-of-hope/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.js";
import plugin_Jozdw60ZsE from "/home/runner/work/hub-of-hope/hub-of-hope/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import slideovers_X7whXyrIWR from "/home/runner/work/hub-of-hope/hub-of-hope/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_oqljskDzoR from "/home/runner/work/hub-of-hope/hub-of-hope/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_ETtiVmZNmO from "/home/runner/work/hub-of-hope/hub-of-hope/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_OVoKJro5pc from "/home/runner/work/hub-of-hope/hub-of-hope/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_WLsn00x1qh from "/home/runner/work/hub-of-hope/hub-of-hope/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import sentry_client_VdOeuw25Mb from "/home/runner/work/hub-of-hope/hub-of-hope/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/home/runner/work/hub-of-hope/hub-of-hope/.nuxt/sentry-client-config.mjs";
import _1_firebase_client_GtorZ4LfAB from "/home/runner/work/hub-of-hope/hub-of-hope/node_modules/@hiyield/nuxt-layer-firebase/plugins/1.firebase.client.ts";
import vue3_google_map_HFQmKMRED9 from "/home/runner/work/hub-of-hope/hub-of-hope/plugins/vue3-google-map.ts";
import usePublicFetch_linaTYKW6O from "/home/runner/work/hub-of-hope/hub-of-hope/plugins/usePublicFetch.ts";
import cookieconsent_client_k0DKrALfet from "/home/runner/work/hub-of-hope/hub-of-hope/plugins/cookieconsent.client.ts";
export default [
  payload_plugin_qLmFnukI99,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_zbacBAXhj0,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  primevue_plugin_egKpok8Auk,
  plugin_client_XxbS7tGyhp,
  plugin_Jozdw60ZsE,
  slideovers_X7whXyrIWR,
  modals_oqljskDzoR,
  colors_ETtiVmZNmO,
  plugin_client_OVoKJro5pc,
  plugin_WLsn00x1qh,
  sentry_client_VdOeuw25Mb,
  sentry_client_config_o34nk2sJbg,
  _1_firebase_client_GtorZ4LfAB,
  vue3_google_map_HFQmKMRED9,
  usePublicFetch_linaTYKW6O,
  cookieconsent_client_k0DKrALfet
]