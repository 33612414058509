import { computed, ref } from 'vue'

export const iosGtmFrameFrameId = 'analytics-frame'
export const iosGtmFrameRouteParam = 'iosGtm'

// this is the iframe - it's stored globally so we can access it from the app
const frame = ref<HTMLIFrameElement | null>(null)

/**
 * This is used to put an iframe in the app.vue.
 * It's added on ios native to allow GTM to work from within the frame.
 * The cookie/consent is synced to the iframe from the app which only loads the GTM script
 * if the user has consented and accepted the ATT prompt.
 * Then all GTM events are sent to the iframe which then sends them to GTM.
 * This is because GTM doesn't natively work in ios apps because of the capacitor:// protocol.
 */
export const useIsIosGtmFrame = () => {
  const isIosGtmFrame = computed(() => {
    const iosGtm = useRoute().query?.[iosGtmFrameRouteParam] as string | undefined
    return iosGtm === 'true'
  })

  return { isIosGtmFrame, frame, frameId: iosGtmFrameFrameId, routeParam: iosGtmFrameRouteParam }
}
