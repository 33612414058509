import { FilterXSS } from 'xss'
import { xssConfig } from '../utils/xssConfig'
const xssValidator = new FilterXSS(xssConfig())

/**
 * This is a front end helper for validating request body
 * against xss attacks. It is used to filter out any xss attacks.
 * The server will 400 before it hits the route handler if the request
 * is different from the validated request. So we need to filter
 * for xss all client data, which will then be filtered in the same way on
 * the server.
 */
export const useXssValidation = (body: unknown) => {
  const processedValue = xssValidator.process(JSON.stringify(body))
  return JSON.parse(processedValue)
}
