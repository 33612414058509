import type { AggregationsAggregate, SearchResponse } from '@elastic/elasticsearch/lib/api/types'
import { stringifyQuery } from 'ufo'
import type { ElasticServiceWithDistance } from '~/types/ElasticService'
import type { FilterIds } from '~/types/FilterIds'
import type { ServiceSearchFilters } from '~/utils/serviceSearch/types/ServiceSearchFilters'

type ServiceSearchEndpointResponse = {
  result: SearchResponse<ElasticServiceWithDistance, Record<string, AggregationsAggregate>>
  next: string
}

// these are used for defaults around the place, but should not be used in this store
export const COORD_LAT = 51.454514
export const COORD_LON = -2.58791

export const useServiceSearchStore = defineStore('serviceSearch', () => {
  // A cache where the string is the query that was sent to the server (without the PIT)
  // and the value is the response from the server
  const cache = reactive<Record<string, ServiceSearchEndpointResponse>>({})

  const currentPage = ref(1)

  // the search text for location
  const locationSearch = ref('')

  // used to save the scroll position on the front end search results page
  const scrollPosition = ref(0)

  // is the filter menu open - this is used on the front end search results page
  // to keep it's state when going to result and back
  const filterMenuOpen = ref(true)

  // last searched query
  const currentQuery = ref<string>('')
  const currentResults = computed(() => {
    return cache[currentQuery.value]?.result?.hits?.hits?.map((x) => x._source) || []
  })

  const currentQueryTotal = computed(() => {
    const total = cache[currentQuery.value]?.result?.hits?.total || 0
    return typeof total === 'number' ? total : total.value
  })

  const currentQueryPages = computed(() => {
    return Math.ceil(currentQueryTotal.value / (filters.size || 20))
  })

  const hasMore = computed(() => {
    return currentQueryPages.value > currentPage.value
  })

  // controls whether the search should be done automatically when the filters change
  const autoSearch = ref(false)
  // the debounce for autosearch in milliseconds
  const autoSearchDebounce = ref(500)

  // Will set to true when first search has been done
  const hasSearched = ref(false)

  // controls if we need at least the location and support need filters to be set
  // in order to auto search
  const requireLocationOrSupportNeedForAutoSearch = ref(false)

  // the filters that the ui maps to
  // if you add to this, remember to add to the clearFilters function below
  const filters = reactive<ServiceSearchFilters>(defaultFilters())

  const selectedAgeBrackets = reactive<{ value: Record<string, boolean> }>({ value: {} })

  // Calculates how many filters are active
  const numberOfActiveFilters = computed(() => {
    // Extract only relevant filters from filter object
    const relevantFilters = [
      filters.supportNeeds,
      filters.deliveryFormats,
      filters.serviceProviderTypes,
      filters.amenitiesAndAccessibility,
      filters.preferences,
      // For age range filter join it by a comma - we can then check if it's filtered if it's not equal to '0,122'
      filters.ageRange.join(','),
      filters.ageVerified,
      filters.national,
      filters.fuzzyServiceName,
      filters.status,
      filters.open247,
      filters.openNow,
      filters.openWeekends,
      filters.openEvenings,
      filters.expiresIn,
      filters.serviceAdmin
    ]

    // Filter though filter values and return only active filters
    const activeFilters = relevantFilters.filter((filter) => {
      if (Array.isArray(filter)) {
        return filter.length > 0
      } else {
        return filter !== undefined && filter !== false && filter !== '' && filter !== '0,122'
      }
    })
    return activeFilters.length
  })

  // Used to store the location search value in memory so input state on location search is maintained on re-mount if needed
  const lastSearchedLocation = ref<string>('')

  const clearFilters = (opts?: { keep?: (keyof ServiceSearchFilters)[] }) => {
    const keep = opts?.keep || []
    const defaults = defaultFilters()
    Object.keys(defaults).forEach((key) => {
      if (keep.includes(key as keyof ServiceSearchFilters)) return
      /* @ts-expect-error - TS nevery knows what key/value is */
      filters[key] = defaults[key]
    })
  }

  /** The search function to query elastic and cache the results in-store */
  const searching = ref(false)
  const search = async (opts?: { refresh?: boolean }) => {
    try {
      const refresh = opts?.refresh
      searching.value = true
      // reset the scroll position
      scrollPosition.value = 0

      // create the query object/string
      const queryString = stringifyQuery({ ...filters })

      // set the current query
      currentQuery.value = queryString

      // reset the page to 1
      currentPage.value = 1

      // check if the query is in the cache
      const cached = cache[queryString]

      // if we're not an admin route, fire the GA event
      if (!isAdminRoute()) {
        useGaEvent('service_search', {
          cached: Boolean(cached),
          filters: useGaEventHelpers().getGaFiltersObject()
        })
      }

      if (cached && !refresh) {
        searching.value = false
        // return the cached results
        return cached
      }

      // hit the server with the query and get the results
      const fetchToUse = getFetchToUse()
      const response = await fetchToUse.fetch<ServiceSearchEndpointResponse>(
        `/api/v2/public/service/search?${queryString}`
      )

      // add to cache (which will show them through currentResults)
      cache[queryString] = response
      searching.value = false
      hasSearched.value = true

      // if we're not an admin route, fire the GA event
      if (!isAdminRoute()) {
        nextTick(() => {
          useGaEvent('service_search_results', useGaEventHelpers().getGaServiceSearchResults())
        })
      }

      return response
    } catch (e) {
      console.error('Search failed in serviceSearchStore')
      console.error(e)
      useToast().add({
        color: 'red',
        title: 'Search Failed'
      })
      searching.value = false
      throw e
    }
  }

  /** The next page function which will get the next page for the current search */
  const loadingNextPage = ref(false)
  const nextPage = async () => {
    try {
      // if there is no current query, just search
      const lastQueryValue = currentQuery.value
      if (!lastQueryValue) return search()

      // if there is no cache for the current query, just search
      const cached = cache[lastQueryValue]
      if (!cached) return search()

      // if there is no next page, just search
      const { next } = cached
      if (!next) return search()

      // hit the server with the next page query and get the results
      loadingNextPage.value = true
      const fetchToUse = getFetchToUse()
      const res = await fetchToUse.fetch<ServiceSearchEndpointResponse>(next)
      const { next: newNext, result } = res
      // update the cache with the new results
      cache[lastQueryValue].next = newNext
      cache[lastQueryValue].result.hits.hits.push(...result.hits.hits)

      loadingNextPage.value = false
      return cache[lastQueryValue]
    } catch (e) {
      console.error('Next page load failed in serviceSearchStore')
      console.error(e)
      useToast().add({
        color: 'red',
        title: 'Next page load failed'
      })
      loadingNextPage.value = false
      throw e
    }
  }

  /** Send a request to generate and download a CSV file of services */
  const isExporting = ref(false)
  const exportCSV = async () => {
    // we're doing it this way so we can show a loading spinner
    try {
      isExporting.value = true
      const csvResponse = await $authedFetch().fetch<Blob>(
        `/api/v2/admin/service/export?${currentQuery.value}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'text/csv'
          },
          responseType: 'blob'
        }
      )

      const url = window.URL.createObjectURL(csvResponse)
      const a = document.createElement('a')
      a.href = url
      a.download = `services-export-${new Date().toISOString()}.csv`
      a.click()
      useToast().add({
        color: 'green',
        title: 'Document exported and downloaded'
      })
    } catch (e) {
      console.error(e)
      useToast().add({
        color: 'red',
        title: 'Export Failed'
      })
    } finally {
      isExporting.value = false
    }
  }

  const filterCache = ref<string>('')

  // watch the filters and search when they change, if autoSearch is enabled
  // adjust the watcher to the debounce amount
  watchDebounced(
    filters,
    (v) => {
      if (JSON.stringify(v) === filterCache.value) return

      filterCache.value = JSON.stringify(v)

      if (!autoSearch.value) return

      // check they have changed from the defaults - only simple compare needed
      // boot out if filtered search not yet taken place and filters are the same as the defaults
      if (!hasSearched.value && JSON.stringify(filters) === JSON.stringify(defaultFilters())) return

      // check that we meet the requirements for auto search
      if (requireLocationOrSupportNeedForAutoSearch.value) {
        const hasCoords = filters.coordinatesLat && filters.coordinatesLon
        const hasSupportNeeds = filters.supportNeeds.length > 0
        // OR if the user has searched for fuzzyServiceName
        if (!hasCoords && !hasSupportNeeds && !filters.fuzzyServiceName) return
      }

      // otherwise search
      search()
    },
    { immediate: false, debounce: autoSearchDebounce }
  )

  const clearCache = () => {
    Object.keys(cache).forEach((key) => delete cache[key])
  }

  // A list of filters that should be disabled
  const disabledFilters = ref<FilterIds[]>([])

  return {
    cache,
    filters,
    locationSearch,
    currentResults,
    search,
    nextPage,
    searching,
    loadingNextPage,
    autoSearch,
    autoSearchDebounce,
    isExporting,
    exportCSV,
    currentQuery,
    currentQueryTotal,
    currentQueryPages,
    currentPage,
    clearCache,
    clearFilters,
    hasMore,
    numberOfActiveFilters,
    requireLocationOrSupportNeedForAutoSearch,
    lastSearchedLocation,
    disabledFilters,
    scrollPosition,
    filtersAreDefault,
    filterMenuOpen,
    hasSearched,
    selectedAgeBrackets
  }
})

/** This dictates what fetch to use, and if we fire GA events */
const isAdminRoute = () => {
  const routePath = useRoute().path
  return routePath.startsWith('/admin')
}

const getFetchToUse = () => {
  const isAdmin = isAdminRoute()
  const fetchToUse = isAdmin ? $authedFetch() : $publicFetch()
  return fetchToUse
}

const defaultFilters = (): ServiceSearchFilters => ({
  supportNeeds: [],
  deliveryFormats: [],
  serviceProviderTypes: [],
  amenitiesAndAccessibility: [],
  preferences: [],
  ageRange: [0, 122],
  ageVerified: undefined,
  distance: -1,
  serviceAdmin: [],
  national: false,
  fuzzyServiceName: '',
  size: 20,
  coordinatesLat: 0,
  coordinatesLon: 0,
  sortCol: 'name',
  sortDirection: 'asc',
  status: [],
  open247: false,
  openNow: false,
  openWeekends: false,
  openEvenings: false,
  expiresIn: []
})

const filtersAreDefault = (filters: ServiceSearchFilters): boolean => {
  return JSON.stringify(filters) === JSON.stringify(defaultFilters())
}
