import type { ElasticServiceWithDistance } from '~/types/ElasticService'
import type { GAEventLocationSearchData } from '~/types/GoogleAnalytics/GAEventLocationSearchData'
import type { GAEventServiceSearchResultsData } from '~/types/GoogleAnalytics/GAEventServiceSearchResultsData'
import type { GAFilters } from '~/types/GoogleAnalytics/GAFilters'
import type { GAService } from '~/types/GoogleAnalytics/GAService'
import type { PublicService } from '~/types/PublicService'
import type { ServiceSearchFilters } from '~/types/typedoc/iFrames'
import { serviceIsOpenNow } from '~/utils/openingTimes/serviceIsOpenNow'

/** Some helpers for getting/formatting data for GA events */
export const useGaEventHelpers = () => {
  return {
    getGaFiltersObject,
    getGaServiceSearchResults,
    elasticServiceToGaService,
    publicServiceToGaService,
    getGaUserLocationData
  }
}

/** Get the location data for a user */
const getGaUserLocationData = (): GAEventLocationSearchData | null => {
  const { location, selectedLocationSuggestion } = storeToRefs(useServicesLocationStore())
  const { locationSearch } = storeToRefs(useServiceSearchStore())

  const lat = convertCoord(location.value?.latitude)
  const lng = convertCoord(location.value?.longitude)

  if (lat && lng) {
    return {
      lat: convertCoord(location.value.latitude),
      lng: convertCoord(location.value.longitude),
      query: locationSearch.value || null,
      selection: selectedLocationSuggestion.value?.description || null
    }
  } else {
    return null
  }
}

/** Get the service search results */
const getGaServiceSearchResults = () => {
  const { currentPage, currentQuery, currentResults, hasMore, currentQueryTotal } =
    storeToRefs(useServiceSearchStore())
  const filters = getGaFiltersObject()

  const data: GAEventServiceSearchResultsData = {
    filters,
    currentPage: currentPage.value,
    currentQuery: currentQuery.value,
    hasMorePages: hasMore.value,
    totalResults: currentQueryTotal.value,
    services: currentResults.value.filter(exists).map((service) => elasticServiceToGaService(service))
  }

  return data
}

/** Get a GA version of the filters object */
const getGaFiltersObject = () => {
  const searchStore = useServiceSearchStore()
  const supportNeedsStore = useSupportNeedCategoryStore()

  const { disabledFilters, locationSearch, numberOfActiveFilters } = storeToRefs(searchStore)
  const { filters, filtersAreDefault } = searchStore

  if (filtersAreDefault(filters)) {
    return null
  }

  // The support needs in the filters as objects for GA events
  const { supportNeedCategories } = storeToRefs(supportNeedsStore)
  const supportNeedFilterObjects = computed(() => {
    return filters.supportNeeds.map((uid) => {
      const supportNeed = supportNeedCategories.value.find((x) => x.uid === uid)
      return supportNeed ? { uid, name: supportNeed.displayText } : { uid, name: 'Unknown' }
    })
  })

  // json the filters to prevent mutation
  const clonedFilters = JSON.parse(JSON.stringify(filters)) as ServiceSearchFilters

  const gaFilterObject: GAFilters = {
    disabledFilters: disabledFilters.value,
    filters: {
      ...clonedFilters,
      coordinatesLat: convertCoord(clonedFilters.coordinatesLat),
      coordinatesLon: convertCoord(clonedFilters.coordinatesLon)
    },
    locationSearch: locationSearch.value,
    numberOfActiveFilters: numberOfActiveFilters.value,
    supportNeedFilterObjects: supportNeedFilterObjects.value
  }

  return gaFilterObject
}

/** Convert an elastic service object into a GA service object */
const elasticServiceToGaService = (service: ElasticServiceWithDistance): GAService => {
  const gaService: GAService = {
    uid: service.uid,
    name: service.name,
    wasOpen: serviceIsOpenNow(service.openingTimes),
    distanceFromUserMiles: service.distance.miles,
    distanceFromUserKm: service.distance.meters / 1000,
    lat: convertCoord(service.coordinates.lat),
    lng: convertCoord(service.coordinates.lon)
  }

  return gaService
}

/**
 * Convert a public service to a GA service. Pass in the elastic service if you have it to get the distance.
 */
const publicServiceToGaService = (service: PublicService): GAService => {
  // see if we can get the elastic service from the current results to get the distance
  const { currentResults } = storeToRefs(useServiceSearchStore())
  const elasticService = currentResults.value.find((x) => x && x.uid === service.uid)

  const gaService: GAService = {
    uid: service.uid,
    name: service.name,
    wasOpen: serviceIsOpenNow(service.openingTimes),
    distanceFromUserMiles: elasticService ? elasticService.distance.miles : null,
    distanceFromUserKm: elasticService ? elasticService.distance.meters / 1000 : null,
    lat: convertCoord(service.coordinates.lat),
    lng: convertCoord(service.coordinates.lng)
  }
  return gaService
}

/** Just check if something exists */
const exists = (service: ElasticServiceWithDistance | undefined): service is ElasticServiceWithDistance => {
  return service !== undefined
}

/** checks for valid coords and return it or null */
const convertCoord = (coord: number | null | undefined) => {
  // check for undefined null, 0, false
  if (!coord) return null

  // check for NaN
  if (isNaN(coord)) return null

  // check for -1
  if (coord === -1) return null

  return coord
}
