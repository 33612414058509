import type { OpeningTimes } from '~/types/OpeningTimes'
import { getCurrentDayOfWeek } from './getCurrentDayOfWeek'

export const getOpeningTimesForCurrentDay = (openingTimes: OpeningTimes) => {
  // Get the current day of the week
  const { currentDayOfWeek } = getCurrentDayOfWeek()

  // Get the opening times from that day
  const openingTimesForCurrentDay = openingTimes[currentDayOfWeek]
  return openingTimesForCurrentDay
}
