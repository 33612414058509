import { isNative } from './isNative'

/**
 * get the base url to use for api requests with $publicFetch.
 * In web environments, this is the same as the current host.
 * In native environments, this needs to be set to the base url of the api.
 * This is used in $publicFetch to set the base url for all requests.
 * empty string if not native means we don't set a base url so it uses the current host.
 */
export const getBaseUrl = () => {
  const { hostName, isNative } = useRuntimeConfig().public
  console.log({ isNative, hostName })
  // boot out if not native
  if (!isNative) return ''
  // return the base url for native
  return hostName
}
