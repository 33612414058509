import { useXssValidation } from '~/composables/useXssValidation'
import type { SupportNeedCategory, SupportNeedCategoryInput } from '~/types/SupportNeedCategory'

export default defineStore('supportNeedCategory', () => {
  const isLoading = ref(false)
  const supportNeedCategories = ref<SupportNeedCategory[]>([])

  async function fetchSupportNeedCategories(opts?: { refresh?: boolean }) {
    try {
      // if we are currently loading, wait for the loading to finish
      if (isLoading.value) {
        await until(isLoading).toBe(false)
        return supportNeedCategories.value
      }

      // if we already have the categories and we're not force refreshing, return them
      const alreadyFetchedAndNoRefresh = supportNeedCategories.value.length && !opts?.refresh
      if (alreadyFetchedAndNoRefresh) {
        return supportNeedCategories.value
      }

      // check if the user is either an admin or not
      const { userDoc } = useAuthStore()
      const isAdmin =
        userDoc?.role === 'ctsAdmin' ||
        userDoc?.role === 'superAdmin' ||
        userDoc?.role === 'networkAdmin' ||
        userDoc?.role === 'serviceAdmin'

      const fetchToUse = isAdmin ? $authedFetch() : $publicFetch()

      isLoading.value = true
      supportNeedCategories.value = await fetchToUse.fetch(
        `/api/v2/${isAdmin ? 'admin' : 'public'}/support-need-category/`
      )

      return supportNeedCategories.value
    } catch (error) {
      console.error(error)
      useToast().add({
        color: 'red',
        title: 'Failed to fetch support need categories'
      })
    } finally {
      isLoading.value = false
    }
  }

  // Fetch a support need category by uid
  async function fetchSupportNeedCategory(uid: string) {
    try {
      return await $authedFetch().fetch(`/api/v2/admin/support-need-category/${uid}`)
    } catch (error) {
      console.error(error)
      useToast().add({
        color: 'red',
        title: 'Failed to fetch support need category'
      })
    }
  }

  async function deleteSupportNeedCategory(uid: string) {
    try {
      await $authedFetch().fetch(`/api/v2/admin/support-need-category/${uid}`, { method: 'DELETE' })
      // remove the deleted category from the list
      supportNeedCategories.value = supportNeedCategories.value.filter((category) => category.uid !== uid)
      useToast().add({
        color: 'green',
        title: 'Support need category deleted'
      })
    } catch (error) {
      console.error(error)
      useToast().add({
        color: 'red',
        title: 'Failed to delete support need category'
      })
    }
  }

  async function addSupportNeedCategory(category: SupportNeedCategoryInput) {
    try {
      const response = await $authedFetch().fetch('/api/v2/admin/support-need-category', {
        method: 'POST',
        body: JSON.stringify({ supportNeedCategory: category })
      })
      supportNeedCategories.value.push(response)
      useToast().add({
        color: 'green',
        title: 'Support need category added'
      })

      return response
    } catch (error) {
      console.error(error)
      useToast().add({
        color: 'red',
        title: 'Failed to add support need category'
      })
    }
  }

  // Update support need category
  async function updateSupportNeedCategory(uid: string, category: SupportNeedCategoryInput) {
    try {
      // Check required parameters are passed in
      if (!uid || !category) return
      const response = await $authedFetch().fetch(`/api/v2/admin/support-need-category/${uid}`, {
        method: 'PATCH',
        body: useXssValidation({ updates: category })
      })

      // update the category in the list
      const index = supportNeedCategories.value.findIndex((c) => c.uid === uid)
      supportNeedCategories.value[index] = response

      useToast().add({
        color: 'green',
        title: 'Support need category updated'
      })

      return response
    } catch (error) {
      console.error(error)
      useToast().add({
        color: 'red',
        title: 'Failed to update support need category'
      })
    }
  }

  // Filters out support need categories based on the search term
  function filterCategoriesBySearchTerm(searchTerm: string) {
    if (!searchTerm) return supportNeedCategories.value

    // Transform to lowercase for case-insensitive search
    searchTerm = searchTerm.toLowerCase()

    // if the search is only one letter - just return the items that start with that letter
    if (searchTerm.length === 1) {
      return supportNeedCategories.value.filter((cat) => {
        // Return the items that begin with the search value
        return cat.displayText.toLowerCase().startsWith(searchTerm)
      })
    }

    // otherwise return the items that contain the search value, in either the display text,
    // the alternate phrases, or the glossary definition. Prioritize display text, then alternate phrases, then glossary definition.
    return supportNeedCategories.value
      .map((cat) => {
        let score = 0

        if (cat.displayText.toLowerCase().includes(searchTerm)) {
          score += 3
        } else if (cat.alternatePhrases?.some((phrase) => phrase.toLowerCase().includes(searchTerm))) {
          score += 2
        } else if (cat.glossaryDefinition?.toLowerCase().includes(searchTerm)) {
          score += 1
        }

        return { ...cat, score }
      })
      .filter((cat) => cat.score > 0)
      .sort((a, b) => b.score - a.score)
  }

  return {
    isLoading,
    supportNeedCategories,
    fetchSupportNeedCategory,
    fetchSupportNeedCategories,
    updateSupportNeedCategory,
    deleteSupportNeedCategory,
    addSupportNeedCategory,
    filterCategoriesBySearchTerm
  }
})
