import type { UseFetchOptions } from 'nuxt/app'

export const $publicFetch = () => ({
  fetch: useNuxtApp().$publicFetch
})

export function usePublicFetch<T>(url: string | (() => string), options?: UseFetchOptions<T>) {
  return useFetch(url, {
    ...options,
    $fetch: useNuxtApp().$publicFetch
  })
}
