import { getBaseUrl } from '~/nuxt-config-utils/getBaseUrl'

export default defineNuxtPlugin(() => {
  const baseUrl = getBaseUrl()
  console.log({ baseUrl })
  const publicFetch = $fetch.create({ baseURL: baseUrl })

  // Expose to useNuxtApp().$publicFetch
  return {
    provide: {
      publicFetch
    }
  }
})
