import type { RollbackPublishedService } from './types/ArchivedPublishedService'
import type { AuditLog } from './types/AuditLog'
import type { PublishedService } from './types/PublishedService'
import type { ArchivedService } from './types/ArchivedService'
import type { ServiceArea } from './types/ServiceArea'
import type { ServiceReviewCycle } from './types/ServiceReviewCycle'
import type { Iframe } from './types/Iframe'
import type { ReviewCycleReport } from './types/ReviewCycleReport'
import type { RawFAQ } from './types/FAQ'
import type { ReviewApprovalToken } from './types/ReviewApprovalToken'
import type { User } from '~/types/User'
import type { ApiKey } from '~/types/ApiKey'
import type { Network } from '~/types/Network'
import type { Service } from '~/types/Service'
import type { SupportNeedCategory } from '~/types/SupportNeedCategory'
import type { Note } from '~/types/Note'
import type { NewServiceRequest } from '~/types/NewServiceRequest'
import type { ServiceChangeRequest } from '~/types/ServiceChangeRequest'
import { version } from './app.version'

export default defineAppConfig({
  version,
  hiyieldNuxtLayerFirebaseAuth: {
    /**
     * The amount of retries to pass to $fetch when using the $authedFetch composable.
     * @see https://github.com/unjs/ofetch#%EF%B8%8F-auto-retry
     */
    authedFetchRetryAmount: 0,
    /**
     * The type of the user object to use to type the store.
     * Use type casting here as below. The only thing this is used for is the type.
     * The type cast here will be used to type the `userDoc` ref in the auth store.
     */
    userDocType: {} as User,
    /**
     * The firestore collection in which to store user data.
     */
    userCollection: 'users',
    /**
     * The user defaults to use when creating a new user after signup.
     * Use the special keys `_now_` which will be replaced with the current timestamp in ISO format
     * and `_uid_` which will be replaced with the user's uid.
     */
    userDefaults: {
      created: '_now_',
      uid: '_uid_'
    }
  },
  hiyieldNuxtLayerFirebase: {
    /**
     * This is the name that will be used to create a service
     * account for local development. If it's omitted, we will
     * try to use the name of the development machine's current
     * user. If that fails, we will use nanoid to make a random uid.
     */
    yourName: '',
    /**
     * This will be used to create the useDb() and useServerDb() composables.
     * Read more about this in the readme.
     */
    dbCollections: {
      users: { type: {} as User },
      apiKeys: { type: {} as ApiKey },
      networks: { type: {} as Network },
      services: { type: {} as Service },
      auditLogs: { type: {} as AuditLog<object> },
      publishedServices: { type: {} as PublishedService },
      rollbackPublishedServices: { type: {} as RollbackPublishedService },
      archivedServices: { type: {} as ArchivedService },
      supportNeedCategories: { type: {} as SupportNeedCategory },
      serviceAreas: { type: {} as ServiceArea },
      notes: { type: {} as Note },
      newServiceRequests: { type: {} as NewServiceRequest },
      serviceChangeRequests: { type: {} as ServiceChangeRequest },
      iframe: { type: {} as Iframe },
      faqs: { type: {} as RawFAQ },
      serviceReviewCycles: { type: {} as ServiceReviewCycle },
      reviewCycleReports: { type: {} as ReviewCycleReport },
      reviewApprovalToken: { type: {} as ReviewApprovalToken }
    },
    /**
     * If the firebase storage functions should be available.
     */
    initStorage: {
      /**
       * If the firebase storage functions should be available on the client.
       */
      client: false,
      /**
       * If the firebase storage functions should be available on the server.
       */
      server: false
    },
    /**
     * If the firebase auth functions should be available.
     */
    initAuth: {
      /**
       * If the firebase auth functions should be available on the client.
       */
      client: true,
      /**
       * If the firebase auth functions should be available on the server.
       */
      server: true
    },
    /**
     * If the firebase firestore functions should be available.
     */
    initFirestore: {
      /**
       * If the firebase firestore functions should be available on the client.
       */
      client: true,
      /**
       * If the firebase firestore functions should be available on the server.
       */
      server: true
    }
  },
  ui: {
    primary: 'blue',
    calmBlue: 'calmBlue',
    input: {
      placeholder: 'placeholder-gray-500 dark:placeholder-gray-500',
      color: {
        green: {
          outline: 'border border-gray-400 focus:ring-1 focus:ring-green focus:border-green'
        }
      }
    },
    textarea: {
      color: {
        green: {
          outline: 'border border-gray-400 focus:ring-1 focus:ring-green focus:border-green'
        }
      },
      placeholder: 'placeholder-gray font-light font-poppins',
      icon: {
        base: 'flex-shrink-0 text-blue dark:text-gray-500'
      }
    },
    checkbox: {
      // confirmed with Jamie that this is fine to change sitewide
      label: 'text-base font-light',
      rounded: 'rounded-sm',
      default: {
        color: 'blue'
      },
      ring: 'focus:ring-0 focus:outline-2 focus:outline-blue focus:outline-offset-0'
    },
    modal: {
      base: 'p-6'
    },
    breadcrumb: {
      base: 'flex py-1 px-2 rounded-md items-center gap-x-1.5 group font-medium min-w-0',
      active: 'text-gray-700 bg-gray-50 font-semibold'
    },
    button: {
      color: {
        blue: {
          solid:
            'bg-blue-500 text-[var(--hoh-color-primary-text-colour)] hover:bg-blue-400 transition-colors duration-200 focus:ring-2 focus:ring-blue-200'
        },
        calmBlue: {
          solid:
            'bg-calmBlue-400 text-black hover:bg-calmBlue-500 transition-colors duration-200 focus:ring-2 focus:ring-blue-200'
        },
        green: {
          solid:
            'bg-green-500 text-[var(--hoh-color-secondary-text-colour)] hover:bg-green-400 transition-colors duration-200 focus:ring-2 focus:ring-blue-200'
        },
        pink: {
          solid:
            'bg-pink-500 text-almostBlack hover:bg-pink-400 transition-colors duration-200 focus:ring-2 focus:ring-blue-200'
        },
        white: {
          ghost:
            'hover:underline transition-colors duration-200 font-semibold text-gray-600 focus:ring-2 focus:ring-blue-200'
        },
        'green-border': {
          outline:
            'py-2/5 px-4 border border-green text-black hover:bg-green hover:text-white transition-colors duration-200'
        }
      },
      variant: {
        'tab-left':
          'rounded-l-md rounded-r-none py-2 px-2.5 border border-gray-300 hover:bg-blue hover:text-white transition-colors duration-200',
        'tab-right':
          'rounded-r-md rounded-l-none py-2 px-2.5 border-y border-r border-gray-300 hover:bg-blue hover:text-white transition-colors duration-200'
      },
      icon: {
        size: {
          sm: 'size-3'
        }
      },
      default: {
        color: 'blue',
        variant: 'solid'
      }
    },
    accordion: {
      container: 'border-b border-gray-200 :last:border-b-0'
    },
    dropdown: {
      divide: 'divide-y-0',
      padding: 'p-0'
    },
    alert: {
      wrapper: 'text-center',
      inner: 'max-w-4xl mx-auto md:px-11',
      padding: 'p-8',
      color: {
        blue: {
          solid: 'bg-blue-50'
        }
      }
    },
    card: {
      body: {
        base: 'divide-y divide-solid divide-gray-200',
        padding: 'sm:p-0 p-0'
      },
      header: {
        background: 'bg-[#F9FAFB] rounded-t-lg'
      }
    },
    formGroup: {
      label: {
        base: 'text-base md:text-sm'
      },
      help: 'text-base',
      description: '!text-base md:text-sm'
    }
  }
})
