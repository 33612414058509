<template>
  <UModal v-model="isOpen">
    <h4 class="text-lg font-semibold mb-6">You are offline</h4>
    <p class="mb-6">You have lost internet connection - please reconnect to use the app</p>
    <div class="flex flex-wrap gap-3 justify-end mt-5">
      <UButton color="primary" @click="close()">Okay</UButton>
    </div>
  </UModal>
</template>

<script setup lang="ts">
import { useOnline } from '@vueuse/core'

const online = useOnline()

const hasManuallyClosed = ref(false)
const isOpen = computed({
  get() {
    return !online.value && !hasManuallyClosed.value
  },
  set(value) {
    if (value === false) {
      hasManuallyClosed.value = true
    }
  }
})

const close = () => {
  hasManuallyClosed.value = true
}
</script>
