import { setHeaders } from 'h3'
import merge from 'lodash.merge'
import { iosGtmFrameRouteParam } from '~/composables/useIsIosGtmFrame'

import { hexToRgb } from '~/lib/hexToRgb'
import { shadesOf } from '~/lib/tailwind-shades'
import { useIframeConfigStore } from '~/stores/iframeConfigStore'

const DEBUG = true

export default defineNuxtRouteMiddleware(async (to) => {
  // skip middleware on client side entirely
  if (import.meta.client) {
    if (DEBUG) console.log('Skipping middleware on client side')
    return
  }

  // Modify the headers to allow the iframe to be embedded
  const requestEvent = useRequestEvent()
  if (!requestEvent) {
    if (DEBUG) console.log('No request event found')
    throw new Error('No request event found')
  }

  // skip middleware if we're on the iframe test page
  if (to.path === '/iframe/services/test') {
    if (DEBUG) console.log('Skipping middleware on test page')
    return setHeaders(requestEvent, selfHeaders())
  }

  const iosGtm = to.query?.[iosGtmFrameRouteParam] as string | undefined
  if (DEBUG) console.log('iosGtm:', iosGtm)

  if (iosGtm === 'true') {
    if (DEBUG) console.log('Skipping middleware for ios hack')
    // set headers to allow from any domain and return
    return setHeaders(requestEvent, {
      'Content-Security-Policy': 'frame-ancestors *',
      'X-Frame-Options': 'ALLOW-FROM *'
    })
  }

  const orgId = to.query.org_id as string
  if (!orgId) {
    if (DEBUG) console.log('No orgId found')
    return setHeaders(requestEvent, selfHeaders())
  }

  if (DEBUG) console.log('orgId:', orgId)

  let referer = useRequestHeaders(['referer'])?.referer

  if (DEBUG) console.log('referer:', referer)

  /**
   * note that we won't have a referer if this is not in an iframe
   * some services link out to hoh directly from their app, but still want
   * it to be whitelabelled. In this case, we don't need to worry about a referer
   * block, but we still need to set up the iframe.
   * So we check for no referer and set the headers to self, but carry on
   * with the rest of the setup.
   */

  if (!referer) {
    if (DEBUG) console.log('No referer found')
    setHeaders(requestEvent, selfHeaders())
  }

  // Use URL to extract only the base domain (protocol + host + port)
  // default to the public host name if no referer is found - from here on
  // out we only use this for the iframe domain check, not for the headers
  const refererUrl = new URL(referer || useRuntimeConfig().public.hostName)
  referer = `${refererUrl.protocol}//${refererUrl.host}`

  if (DEBUG) console.log('referer:', referer)

  // grab a secret token to prevent anybody from hitting this endpoint
  const secretToken = useRuntimeConfig().middlewareAuthToken

  // cannot use $authedFetch here
  const iframeDoc = await $fetch(`/api/v2/admin/iframe/${orgId}/middleware`, {
    method: 'GET',
    headers: { Authorization: `Bearer ${secretToken}` }
  })

  // if no referral domain is found, then we should not allow the iframe to be embedded
  if (!iframeDoc) {
    if (DEBUG) console.log('No iframeDoc found')
    return setHeaders(requestEvent, selfHeaders())
  }

  const {
    referralDomain,
    filters,
    highLightPrimaryColor,
    highLightSecondaryColor,
    disabledFilters,
    removeBorderRadius,
    throwWarningOnDomainMismatch,
    isLive
  } = iframeDoc

  // if this iframe is not live, then we should now allow it to be embedded
  // we don't throw here, because we still want to test the embed on self
  if (!isLive) {
    if (DEBUG) console.log('iframe is not live')
    return setHeaders(requestEvent, selfHeaders())
  }

  // if there's no referral domain, and we should throw a warning on domain mismatch,
  // then we should not allow the iframe to be embedded
  if (!referralDomain && throwWarningOnDomainMismatch) {
    if (DEBUG) console.log('No referral domain found and throwWarningOnDomainMismatch is true')
    return setHeaders(requestEvent, selfHeaders())
  }

  // otherwise, we're good to go

  // set the headers to allow the iframe to be embedded
  if (throwWarningOnDomainMismatch) {
    // only allow the iframe to be embedded from the referral domain
    if (DEBUG) console.log('Setting headers to allow iframe to be embedded from referral domain')
    setHeaders(requestEvent, {
      'Content-Security-Policy': `frame-ancestors ${referralDomain} ${useRuntimeConfig().public.hostname}`,
      'X-Frame-Options': 'ALLOW-FROM ' + referralDomain
    })
  } else {
    // allow the iframe to be embedded from any domain
    if (DEBUG) console.log('Setting headers to allow iframe to be embedded from any domain')
    setHeaders(requestEvent, {
      'Content-Security-Policy': 'frame-ancestors *',
      'X-Frame-Options': 'ALLOW-FROM *'
    })
  }

  // fill up the search store with the preset filters
  if (DEBUG) console.log('Setting up search store with preset filters')
  storeToRefs(useServiceSearchStore()).autoSearch.value = false
  merge(useServiceSearchStore().filters, filters)

  // set any disabled filters in the search store
  if (DEBUG) console.log('Setting up search store with disabled filters')
  useServiceSearchStore().disabledFilters = disabledFilters || []

  // fill up the iframe config store
  if (DEBUG) console.log('Setting up iframe config store')
  storeToRefs(useIframeConfigStore()).iframeConfig.value = iframeDoc

  // set the tailwind theme by adjusting the body class
  if (highLightPrimaryColor && highLightSecondaryColor) {
    if (DEBUG) console.log('Setting up tailwind theme')
    // create the shades
    const primaryShades = shadesOf(highLightPrimaryColor)
    const secondaryShades = shadesOf(highLightSecondaryColor)

    const isPrimaryLight = isLightColor(highLightPrimaryColor)
    const isSecondaryLight = isLightColor(highLightSecondaryColor)

    // add them to the body - do something clever here to make the big style string
    let primaryStyles = Object.entries(primaryShades)
      .map(([key, value]) => `--hoh-color-primary-${key}: ${value};`)
      .join(' ')

    if (isPrimaryLight) {
      primaryStyles += ' --hoh-color-primary-text-colour: #1e1e1e;'
    } else {
      primaryStyles += ' --hoh-color-primary-text-colour: #fff;'
    }

    let secondaryStyles = Object.entries(secondaryShades)
      .map(([key, value]) => `--hoh-color-secondary-${key}: ${value};`)
      .join(' ')

    if (isSecondaryLight) {
      secondaryStyles += ' --hoh-color-secondary-text-colour: #1e1e1e;'
    } else {
      secondaryStyles += ' --hoh-color-secondary-text-colour: #fff;'
    }

    // we also need to set the nuxt ui colors
    // these are like: --color-primary-${key}: ${hexToRgb(value)};`)
    // as seen here: https://github.com/nuxt/ui/blob/ce955d24f1dfd222e87ce88428c0612c3f13cd50/docs/plugins/ui.ts#L14
    const primaryNuxtUiColors = Object.entries(primaryShades)
      .map(([key, value]) => `--color-primary-${key}: ${hexToRgb(value)};`)
      .join(' ')

    // Set the head config after body classes are set
    const useHeadConfig: { bodyAttrs: { style: string }; style?: { children: string }[] } = {
      bodyAttrs: { style: `${primaryStyles} ${secondaryStyles} ${primaryNuxtUiColors}` }
    }

    // Add additional rule to remove border-radius from everything if specified on the iframe
    if (removeBorderRadius) useHeadConfig.style = [{ children: '* { border-radius: 0 !important; }' }]

    useHead(useHeadConfig)
  }

  if (DEBUG) console.log('Done setting up iframe')
})

const selfHeaders = () => {
  return { 'Content-Security-Policy': "frame-ancestors 'self'", 'X-Frame-Options': 'SAMEORIGIN' }
}

/**
 * Parse a color string (hex, rgb, rgba) and return [r, g, b].
 * Throws an error for unsupported or invalid formats.
 */
const parseColorToRGB = (color: string): [number, number, number] => {
  // 1) Try parsing as rgb/rgba
  const rgbRegex = /^rgba?\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})(?:,\s*(0?\.\d+|1(\.0)?))?\s*\)$/
  const rgbMatch = color.match(rgbRegex)

  if (rgbMatch) {
    const [, rStr, gStr, bStr] = rgbMatch
    const r = Math.min(255, parseInt(rStr, 10))
    const g = Math.min(255, parseInt(gStr, 10))
    const b = Math.min(255, parseInt(bStr, 10))
    return [r, g, b]
  }

  // 2) Try parsing as hex (#RGB, #RRGGBB)
  const hexRegex = /^#([\da-fA-F]{3,8})$/
  const hexMatch = color.match(hexRegex)

  if (hexMatch) {
    let hexValue = hexMatch[1]

    // Handle short-hex (#abc)
    if (hexValue.length === 3 || hexValue.length === 4) {
      hexValue = hexValue
        .split('')
        .map((char) => char + char)
        .join('') // #abc -> #aabbcc
    }

    // Now parse the first 6 digits as RGB
    const r = parseInt(hexValue.slice(0, 2), 16)
    const g = parseInt(hexValue.slice(2, 4), 16)
    const b = parseInt(hexValue.slice(4, 6), 16)
    return [r, g, b]
  }

  // 3) If no format matched, throw or return a default
  throw new Error(`Unsupported color format: "${color}"`)
}

const isLightColor = (color: string): boolean => {
  const [r, g, b] = parseColorToRGB(color)

  // HSP equation http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b))

  return hsp > 127.5
}
