import * as CookieConsent from 'vanilla-cookieconsent'
import { iosGtmFrameFrameId } from '~/composables/useIsIosGtmFrame'

export default defineNuxtPlugin(() => {
  const updateGtagConsent = async () => {
    // check if the user has accepted the analytics category
    const acceptedAnalytics = CookieConsent.acceptedCategory('analytics')

    // if we're in native ios, we need to handle the ATT permission request if the user accepts analytics
    const { isNative, isIos } = useRuntimeConfig().public
    if (isNative && isIos && acceptedAnalytics) {
      // handle ATT permission request on iOS
      const { AppTrackingTransparency } = await import('capacitor-plugin-app-tracking-transparency')
      let { status: trackingStatus } = await AppTrackingTransparency.getStatus()
      // if the user has not authorized tracking, request permission
      if (trackingStatus !== 'authorized') {
        const { status: requestPermissionStatus } = await AppTrackingTransparency.requestPermission()
        trackingStatus = requestPermissionStatus
      }

      // if the user does not give permission, boot out and do not enable analytics
      if (trackingStatus !== 'authorized') {
        console.log('User has not given permission to track')
        return
      }

      // try to find the analytics iframe and accept cookies inside it
      const frame = document.getElementById(iosGtmFrameFrameId) as HTMLIFrameElement | null
      if (frame?.contentWindow) {
        frame.contentWindow.postMessage({ type: 'command', command: 'acceptAnalyticsCookies', args: [] }, '*')
      }
    }

    // otherwise, update the gtag consent to start tracking what the user has accepted

    const { proxy } = useScriptGoogleTagManager()

    /** @ts-expect-error - gtag will not be known, it's on window */
    gtag('consent', 'update', {
      ad_storage: acceptedAnalytics ? 'granted' : 'denied',
      ad_user_data: acceptedAnalytics ? 'granted' : 'denied',
      ad_personalization: acceptedAnalytics ? 'granted' : 'denied',
      analytics_storage: acceptedAnalytics ? 'granted' : 'denied',
      functionality_storage: CookieConsent.acceptedCategory('necessary') ? 'granted' : 'denied',
      personalization_storage: CookieConsent.acceptedCategory('necessary') ? 'granted' : 'denied'
    })

    // fire off the consent_update custom event - this retriggers the gtag tag
    proxy.dataLayer.push({ event: 'consent_update' })
  }

  // on page load, set the default consent
  // updateGtagConsent('default', false)

  CookieConsent.run({
    cookie: { sameSite: 'None' },
    categories: {
      necessary: {
        enabled: true, // this category is enabled by default
        readOnly: true // this category cannot be disabled
      },
      analytics: {
        enabled: true,
        readOnly: false,
        autoClear: {
          // Clear all Google analytics cookies when the user revokes consent of GA
          cookies: [{ name: /^(_ga)/ }, { name: '_gid' }]
        }
      }
    },

    guiOptions: {
      consentModal: { equalWeightButtons: false },
      preferencesModal: { equalWeightButtons: false }
    },

    language: {
      default: 'en',
      translations: {
        en: {
          consentModal: {
            title: 'We use cookies',
            description:
              'By continuing to use this website, you consent to the use of cookies in accordance with our <a href="/privacy-policy">Privacy Policy</a>.',
            acceptAllBtn: 'Accept all',
            acceptNecessaryBtn: 'Reject all',
            showPreferencesBtn: 'Manage Individual preferences'
          },
          preferencesModal: {
            title: 'Manage cookie preferences',
            acceptAllBtn: 'Accept all',
            acceptNecessaryBtn: 'Reject all',
            savePreferencesBtn: 'Accept current selection',
            closeIconLabel: 'Close modal',
            sections: [
              {
                title: 'Strictly Necessary cookies',
                description:
                  'These cookies are essential for the proper functioning of the website and cannot be disabled.',
                linkedCategory: 'necessary'
              },
              {
                title: 'Performance and Analytics',
                description:
                  'These cookies collect information about how you use our website. All of the data is anonymized and cannot be used to identify you.',
                linkedCategory: 'analytics'
              },
              {
                title: 'More information',
                description:
                  'For any queries in relation to my policy on cookies and your choices, please <a href="#contact-page">contact us</a>'
              }
            ]
          }
        }
      }
    },

    // Enable Google analytics on user consent
    onConsent: () => updateGtagConsent(),
    onChange: () => updateGtagConsent()
  })

  return { provide: { cookieConsent: CookieConsent } }
  // nuxtApp.provide('cookieConsent', CookieConsent)
})
