import type { GAEventBase, GAEventTypes } from '~/types/GoogleAnalytics/GAEventBase'
import type { GetGAEventParams } from '~/types/GoogleAnalytics/GAEvents'
import { iosGtmFrameFrameId, useIsIosGtmFrame } from '~/composables/useIsIosGtmFrame'

export const useGaEvent = <T extends GAEventTypes>(event: T, data: GetGAEventParams<T>) => {
  // make sure data is clean - it can be null or undefined and we spead it
  // so it needs to be an object
  const dataAsObject = data || {}

  // make this save for nuxt ssr - only run on client
  if (import.meta.server) return

  // make the base obj - added to every event
  const isIframe = window !== window.top
  const iframeOrigin = isIframe ? document.referrer : null

  // this comes from the store where we set the org id
  const { iframeConfig } = storeToRefs(useIframeConfigStore())
  const iframeOrgId = iframeConfig.value?.uid || null

  const { isNative, isAndroid, isIos, env } = useRuntimeConfig().public

  const userLocation = useGaEventHelpers().getGaUserLocationData()

  // isIos can occur when the app is running in an iframe
  // this is important to catch the init event in the iframe.
  // all other events will be sent through from the patent with
  // isIos and isNative already set.

  const { isIosGtmFrame } = useIsIosGtmFrame()

  const baseData: GAEventBase = {
    event: event,
    isIframe,
    iframeOrigin,
    iframeOrgId,
    isNativeApp: isNative || isIosGtmFrame.value,
    isNativeIOS: isIos || isIosGtmFrame.value,
    isNativeAndroid: isAndroid,
    userLocation
  }

  const wholeDataObj = { ...baseData, ...dataAsObject }

  if (env !== 'production') console.log('GA Event:', event, wholeDataObj)

  const dataLayer = window.dataLayer || []
  dataLayer.push(wholeDataObj)

  // if we're in a native app, we need to push to the iframe dataLayer
  // GTM doesn't work in natvie so we pipe the events through an iframe out to GTM
  // this iframe is in the app.vue
  if (isNative && isIos) {
    // get the iframe
    const frame = document.getElementById(iosGtmFrameFrameId) as HTMLIFrameElement | null
    if (frame?.contentWindow) {
      // push the event to the iframe datalayer
      frame.contentWindow.postMessage(
        { type: 'command', command: 'dataLayer.push', args: [JSON.stringify(wholeDataObj)] },
        '*'
      )
      // this logs the iframe data layer on the parent window (the app) so we can see it's worked
      // this could be commented out in production
      // frame.contentWindow.postMessage({ type: 'command', command: 'datalayerLog', args: [] }, '*')
    }
  }
}
