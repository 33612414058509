const _black = "#000"
const _white = "#fff"
const _transparent = "transparent"
const _current = "currentColor"
const _blue = {"50":"var(--hoh-color-primary-50)","100":"var(--hoh-color-primary-100)","200":"var(--hoh-color-primary-200)","300":"var(--hoh-color-primary-300)","400":"var(--hoh-color-primary-400)","500":"var(--hoh-color-primary-500)","600":"var(--hoh-color-primary-600)","700":"var(--hoh-color-primary-700)","800":"var(--hoh-color-primary-800)","900":"var(--hoh-color-primary-900)","950":"var(--hoh-color-primary-950)","DEFAULT":"var(--hoh-color-primary-500)"}
const _calmBlue = {"50":"#FFFFFF","100":"#FFFFFF","200":"#FFFFFF","300":"#EEF2F8","400":"#D1DCED","500":"#B4C6E1","600":"#8CA8D1","700":"#6489C1","800":"#446CA9","900":"#345381","950":"#2C466D","DEFAULT":"#B4C6E1"}
const _green = {"50":"var(--hoh-color-secondary-50)","100":"var(--hoh-color-secondary-100)","200":"var(--hoh-color-secondary-200)","300":"var(--hoh-color-secondary-300)","400":"var(--hoh-color-secondary-400)","500":"var(--hoh-color-secondary-500)","600":"var(--hoh-color-secondary-600)","700":"var(--hoh-color-secondary-700)","800":"var(--hoh-color-secondary-800)","900":"var(--hoh-color-secondary-900)","950":"var(--hoh-color-secondary-950)","DEFAULT":"var(--hoh-color-secondary-500)"}
const _lightGray = {"50":"#F9F9F9","100":"#F9F9F9","200":"#F9F9F9","300":"#F9F9F9","400":"#F9F9F9","500":"#ECECEA","600":"#D1D1CD","700":"#B7B7AF","800":"#9C9C92","900":"#818175","950":"#727267","DEFAULT":"#ECECEA"}
const _almostBlack = {"50":"#7A7A7A","100":"#707070","200":"#5B5B5B","300":"#474747","400":"#323232","500":"#1E1E1E","600":"#020202","700":"#000000","800":"#000000","900":"#000000","950":"#000000","DEFAULT":"#1E1E1E"}
const _pink = {"50":"#FFFFFF","100":"#FFFFFF","200":"#FFFFFF","300":"#FCF0EE","400":"#F6D1CB","500":"#F0B2A8","600":"#E88878","700":"#DF5D48","800":"#CC3B23","900":"#9D2D1B","950":"#852617","DEFAULT":"#F0B2A8"}
const _red = {"50":"#F2BDB4","100":"#EFAEA3","200":"#E98F80","300":"#E3705D","400":"#DD523B","500":"#CC3B23","600":"#9C2D1B","700":"#6C1F13","800":"#3C110A","900":"#0C0402","950":"#000000","DEFAULT":"#CC3B23"}
const _yellow = {"50":"#FCF1D5","100":"#FBEBC2","200":"#F9DE9C","300":"#F6D176","400":"#F4C44F","500":"#F1B729","600":"#D49B0E","700":"#9F740A","800":"#6B4E07","900":"#362704","950":"#1C1402","DEFAULT":"#F1B729"}
const _gray = {"50":"rgb(var(--color-gray-50) / <alpha-value>)","100":"rgb(var(--color-gray-100) / <alpha-value>)","200":"rgb(var(--color-gray-200) / <alpha-value>)","300":"rgb(var(--color-gray-300) / <alpha-value>)","400":"rgb(var(--color-gray-400) / <alpha-value>)","500":"rgb(var(--color-gray-500) / <alpha-value>)","600":"rgb(var(--color-gray-600) / <alpha-value>)","700":"rgb(var(--color-gray-700) / <alpha-value>)","800":"rgb(var(--color-gray-800) / <alpha-value>)","900":"rgb(var(--color-gray-900) / <alpha-value>)","950":"rgb(var(--color-gray-950) / <alpha-value>)","DEFAULT":"#9da9b7"}
const _primary = {"50":"rgb(var(--color-primary-50) / <alpha-value>)","100":"rgb(var(--color-primary-100) / <alpha-value>)","200":"rgb(var(--color-primary-200) / <alpha-value>)","300":"rgb(var(--color-primary-300) / <alpha-value>)","400":"rgb(var(--color-primary-400) / <alpha-value>)","500":"rgb(var(--color-primary-500) / <alpha-value>)","600":"rgb(var(--color-primary-600) / <alpha-value>)","700":"rgb(var(--color-primary-700) / <alpha-value>)","800":"rgb(var(--color-primary-800) / <alpha-value>)","900":"rgb(var(--color-primary-900) / <alpha-value>)","950":"rgb(var(--color-primary-950) / <alpha-value>)","DEFAULT":"rgb(var(--color-primary-DEFAULT) / <alpha-value>)"}
const _cool = {"50":"#f9fafb","100":"#f3f4f6","200":"#e5e7eb","300":"#d1d5db","400":"#9ca3af","500":"#6b7280","600":"#4b5563","700":"#374151","800":"#1f2937","900":"#111827","950":"#030712"}
const config = { "black": _black, "white": _white, "transparent": _transparent, "current": _current, "blue": _blue, "calmBlue": _calmBlue, "green": _green, "lightGray": _lightGray, "almostBlack": _almostBlack, "pink": _pink, "red": _red, "yellow": _yellow, "gray": _gray, "primary": _primary, "cool": _cool,  }
export { config as default, _black, _white, _transparent, _current, _blue, _calmBlue, _green, _lightGray, _almostBlack, _pink, _red, _yellow, _gray, _primary, _cool }