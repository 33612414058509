import type { OpeningHours } from '~/types/OpeningTimes'
import { militaryTimeToISOString } from './militaryTimeToISOString'
import dayjs from 'dayjs'

export const nowIsWithinOpeningTimes = (openingHour: OpeningHours) => {
  // Transform times into comparable ISO strings
  const openingHourStartDayTime = militaryTimeToISOString(openingHour.startTime)
  const openingHourEndDayTime = militaryTimeToISOString(openingHour.endTime)
  const isMoreThanStartTime = dayjs().isAfter(openingHourStartDayTime)
  const isLessThanEndTime = dayjs().isBefore(openingHourEndDayTime)

  // If current time is between start and end time then service is open now
  return isMoreThanStartTime && isLessThanEndTime
}
