import dayjs from 'dayjs'
import { daysOfWeek } from '~/types/DaysOfWeek'

export const getCurrentDayOfWeek = () => {
  // Get the current day of the week
  let currentDayOfWeekIndex = dayjs().day() - 1
  // dayjs sunday is day 0, but we cound monday as day 0. So on sunday,
  // index ends up being -1. So if that's the case, then index should be 6 for sunday
  if (currentDayOfWeekIndex === -1) currentDayOfWeekIndex = 6

  const currentDayOfWeek = daysOfWeek[currentDayOfWeekIndex]

  return { currentDayOfWeek, currentDayOfWeekIndex }
}
